.product-web {
    background-color: white;
    margin-left: 0;
    display: flex;
    position: relative;
    box-shadow: none!important;
    border-radius: 8px!important;
    flex-direction: column;
}

.text.ellipsis::after {
    content: "...";
    position: absolute;
    right: -12px; 
    bottom: 4px;
  }