.product-list-web {
    height: calc(100vh - 300px);
    display: flex;
    border: 0;
    overflow-y: scroll;
    padding-right: 5px;
    padding-bottom: 40px;
}

.product-list-mobile {
    background-color: --var('primary.main');
    width: 100%;
    overflow-x: scroll;
    padding: 5px;
    display: flex;
    flex-direction: row;
}