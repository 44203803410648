body{
  background: white;
}

.visible {
  transition: opacity 2s ease 0s;
  top: 0;
  left: 0px;
  right: 0px;
  position: absolute;
  z-index: 9000;
}

.hidden {
  opacity: 0; 
  transition: opacity 1s ease 0s;
  top: 0;
  left: 0px;
  right: 0px;
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: -1;
}

.MuiDialog-paper{
  padding: 20px
}

.imageContainer {
  position: relative;
  width: 100%;
  height: calc(100vh - 102px);
}

/* Define the fade animation */
.fadeIn {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fadeOut {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}