%bookmark {
    position: absolute;
    cursor: pointer;
    background-color: transparent;
    top: 3px;
    right: 2px;
    border-radius: 2px;
    padding: 0.5;
    stroke-width: 2px;
}

.is-bookmarked {
    @extend %bookmark;
    color: white;
    stroke: gray;
}

.is-not-bookmarked {
    @extend %bookmark;
    color: #FA5961;
    stroke: white;
}