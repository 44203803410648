.selected-product {
    background: purple;
    position: relative;
  }
  .selected-product:before,
  .selected-product:after {
    content: '';
    position: absolute;
    top: 60px;
    left: -20px;
    right: -20px;
    bottom: 60px;
    border: solid 4px #000;
  }
  .selected-product:before {
    border-top: none;
    border-bottom: none;
  }
  .selected-product:after {
    top: -20px;
    left: 60px;
    right: 60px;
    bottom: -20px;
    border-left: none;
    border-right: none;
  }